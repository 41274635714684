import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import iconImg from "../../images/Info.png";
import whatsImg from "../../images/ws.png";
import configImg from "../../images/configuration.png";
import logo from "../../images/luckywin_logo.png";

const Header = () => {
  const [superAgent, setSuperAgent] = useState([]);

  useEffect(() => {
    fetch("./OnlineMasterAget.JSON")
      .then((res) => res.json())
      .then((data) => {
        setSuperAgent(data);
      });
  }, []);

  let arrayM = Math.floor(Math.random() * superAgent.length + 1);
  const findData = superAgent.find((ele) => ele?.id === arrayM);

  return (
    <div>
      <div className="content-section">
        <div className="">
          <div className="logo-section">
            <img src={logo} alt="" />
          </div>
        </div>
        {/* navigation bar  */}
        <div className="navigation-bar">
          <ul>
            <li>
              <Link className="nav-menu" to="/home">
                <i className="fa-solid fa-house"></i>
              </Link>
            </li>
            <li>
              <Link className="nav-menu" to="/customarservice">
                <i className="fa-duotone fa-question question-icon"></i> SERVICE
              </Link>
            </li>

            <li>
              <Link className="nav-menu" to="/admin">
                <img className="configImg" src={configImg} alt="" /> ADMIN
              </Link>{" "}
              {/*<i className="fa-solid fa-user"></i>// */}
            </li>
            {/* <li>
              <Link className="nav-menu" to="/SubAdmin">
                <img className="configImg" src={configImg} alt="" />
                SUB ADMIN
              </Link>
            </li> */}
            <li>
              <Link className="nav-menu" to="/superagent">
                <img className="configImg" src={configImg} alt="" /> SUPER
              </Link>
            </li>
            <li>
              <Link className="nav-menu" to="/onlineagent">
                <img className="configImg" src={configImg} alt="" />
                MASTER
              </Link>
            </li>
            <li>
              <Link className="nav-menu" to="/OLDNEW">
                <img className="configImg" src={configImg} alt="" />
                OLD/NEW
              </Link>
            </li>
          </ul>
        </div>

        {/* marquee the paragarap    */}
        {/* <marquee className='marquee__list'>
                    <strong>Welcome To <a className='marquee__link' href="https://www.baaji365.live/#/"> Baaji365.Live </a>
                        Enjoy Beeting In Match Odds, Fancy & Live Casino
                    </strong>
                </marquee> */}

        {/* quick master  */}

        <div className="quick-master">
          <div className="quick-master-title">
            <img src={iconImg} alt="" />
            <h1>QUICK MASTER AGENT NUMBER:</h1>
          </div>

          <div className="quick-master-description">
            <div className="table table-bordered">
              <table className="data">
                <tbody>
                  {
                    <tr
                      key={findData?.id}
                      style={{ backgroundColor: "#eee" }}
                      className="radius"
                    >
                      <td>AGENT ID</td>
                      <td>{findData?.id}</td>
                      <td>
                        <div className="socail-icon">
                          {/* <a href={findData?.facebook}>
                                                        <img src={fbImg} id='fb-icon' className='img-fluid icon-img' alt="facebook" />
                                                    </a> */}

                          {/* <a href={findData?.messenger}>
                            <img
                              id="agent_linkm"
                              src={messengerImg}
                              className="img-fluid icon-img"
                              alt="Whatsapp"
                            />
                          </a> */}

                          <a href={findData?.whatsapp}>
                            <img
                              id="agent_link"
                              src={whatsImg}
                              className="img-fluid icon-img"
                              alt="Whatsapp"
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* help site  */}
        <div className="account-info">
          <div className="quick-master-title">
            <img src={iconImg} alt="" />
            <h1> লাকিউইন সাইট লিংকঃ</h1>
          </div>
          <div className="account-info-content">
            <div className="main-content">
              <p>
                <h5>Luckywin SITE MAIN LINK </h5>
                <h5>
                  লাকিউইন মেইন লিঙ্কঃ{" "}
                  <a href="https://luckywin.asia/"> Luckywin.asia</a>{" "}
                </h5>
                <h5>
                  Luckywin SITE FACEBOOK GROUP LINK -{" "}
                  <a
                    className="black_color"
                    href="https://facebook.com/groups/luckywin.official/"
                  >
                    FACEBOOK PAGE LINK
                  </a>{" "}
                </h5>{" "}
                <br />
                <h5>
                  <a href="https://facebook.com/groups/luckywin.official/">
                    লাকিউইন সাইটের ফেসবুক গ্রুপ লিংক{" "}
                  </a>{" "}
                </h5>
                <h5>
                  <a href="https://facebook.com/groups/luckywin.official/">
                    লাকিউইন সাইটের ফেসবুক পেজ লিংক{" "}
                  </a>{" "}
                </h5>
                আমাদের এই এজেন্ট লিষ্ট - ফেসবুক গ্রুপ - ফেসবুক পেজ এবং এই সাইট
                লিংক এর বাইরে আমাদের কোনো লিংক নাই। এর বাইরে গিয়ে লেনদেন করে
                প্রতারিত হলে ফায়ার কোম্পানি সেটার জন্য দায়ী নয়।
              </p>
            </div>
          </div>
        </div>

        {/* acount info  */}

        <div className="account-info">
          <div className="quick-master-title">
            <img src={iconImg} alt="" />
            <h1>কিভাবে একাউন্ট খুলবেন ?</h1>
          </div>
          <div className="account-info-content">
            <div className="main-content">
              <p>
                লাকিউইন সাইটে একাউন্ট করতে হলে আপনার এজেন্ট এর মাধ্যমে একাউন্ট
                খুলতে হবে। এজেন্ট এর মাধ্যমেই টাকা ডিপোজিট এবং উইথড্র করতে হবে।
                আপনি যে এজেন্ট এর কাছ থেকে একাউন্ট খুলবেন তার সাথেই সব সময়
                লেনদেন করতে হবে। ঠিক কোন এজেন্ট কে টাকা দিবেন এবং কিভাবে তার
                সাথে লেনদেন করবেন তার বুঝতে হলে আপনার নিম্বের তথ্য গুলো পড়া
                জরুরী।
              </p>
            </div>
          </div>
        </div>

        {/* agent list  */}

        <div className="account-info">
          <div className="quick-master-title">
            <img src={iconImg} alt="" />
            <h1>এজেন্ট লিস্ট ?</h1>
          </div>
          <div className="account-info-content">
            <div className="main-content">
              <p>
                একাউন্ট খুলতে নিচের অনলাইন এজেন্ট লিস্ট এ ক্লিক করুন। এজেন্ট
                লিষ্ট এর এজেন্ট দের সাথে ইউজার দের শুধু মাত্র হোয়াটসাপ এর
                মাধ্যমে যোগাযোগ করতে হবে। হোয়াটসাপ ছাড়া অন্য কোন মাধ্যমে যোগাযোগ
                করলে বা লেনদেন করলে তা গ্রহনযোগ্য হবে না। হোয়াটসাপ এ যোগাযোগ
                করতে হলে এজেন্ট লিস্টে হোয়াটসাপ আইকন উপরে ক্লিক করুন অথবা ফোন
                নাম্বার টি মোবাইলে সেভ করে তাকে হোয়াটসাপ এ মসেজ পাঠাতে পারবেন।
                হোয়াটসাপ এপ টি আপনার মোবাইলে আগে থেকেই থাকতে হবে। না থাকলে গুগুল
                প্লে থেকে ইন্সটল করে নিন।
                <br />
                <Link to="/onlineagent">অনলাইন মাষ্টার এজেন্ট লিস্টঃ</Link>{" "}
              </p>
            </div>
          </div>
        </div>

        {/* Agent different */}

        <div className="account-info">
          <div className="quick-master-title">
            <img src={iconImg} alt="" />
            <h1>এজেন্ট কয় প্রকার ?</h1>
          </div>

          <div className="account-info-content">
            <div className="main-content">
              <p>
                {" "}
                <Link to="/superagent">অনলাইন সুপার এজেন্ট লিস্টঃ</Link>
                সুপার এজেন্ট রা, ইউজার একাউন্ট এবং মাষ্টার এজেন্ট একাউন্ট খুলে
                দিতে পারেন। কোন সুপার এজেন্ট এর নামে অভিযোগ থাকলে - সরাসরি এডমিন
                কে জানাতে হবে। উপরে মেনু তে এডমিন লিষ্ট দেয়া আছে।
              </p>
              <p>
                <Link to="/onlineagent">অনলাইন মাষ্টার এজেন্ট লিস্টঃ</Link>{" "}
                অনলাইন মাষ্টার এজেন্ট রা, শুধু ইউজার একাউন্ট একাউন্ট খুলে দিতে
                পারেন। কোন মাষ্টার এজেন্ট এর নামে অভিযোগ থাকলে - সরাসরি সুপার
                এজেন্ট এর কাছে অভিযোগ করতে হবে।
                <Link to="/extrapage">
                  {" "}
                  বিস্তারিত জানতে এই লিঙ্ক এ ক্লিক করুন।{" "}
                </Link>
              </p>
              <p>
                লোকাল এজেন্ট লিস্টঃ লোকাল এজেন্টরা শুধু ইউজার একাউন্ট খুলে দিতে
                পারেন। কিন্তু তাদের সাথে প্রতিটি লেনদেন ইউজারকে নিজ দায়িত্বে
                করতে হবে। তাদের নামে কোন অভিযোগ কারো কাছে করা যাবে না। লোকাল
                এজেন্ট সাধারণত– নিজের এলাকায় বা পরিচিতদের সাথে লেনদেন করে ।
                যারা আগে বাজি ধরিয়ে দিত,তাদের কেই মূলত লোকাল এজেন্ট দেয়া
                হয়েছে। লোকাল এজেন্ট অনলাইনে আসে না এবং তারা তাদের পরিচয় গোপন
                রাখতে চায়। লোকাল এজেন্ট এর সাথে অনলাইন এর কোন ইউজার কোন ভাবেই
                লেনদেন করবেন না,আর করে থাকলে তার দায়ভার পুরোটাই আপনার।
              </p>
            </div>
          </div>
        </div>
        <h6 className="copyright">Copyright All Recieve By Luckywin 2024</h6>
      </div>

      {/* want to be agent  */}
      {/* <div className="account-info">
                <div className="quick-master-title">
                    <img src={iconImg} alt="" />
                    <h1>এজেন্ট হওয়ার নিয়ম এবং শর্ত সমূহঃ</h1>
                </div>

                <div className="account-info-content">
                    <ul>
                        <li><i class="fa-solid fa-star"></i>এডমিন শুধু মাত্র সুপার এজেন্ট দিতে পারে।</li>
                        <li><i class="fa-solid fa-star"></i>মাষ্টার এজেন্ট শুধু মাত্র সুপার এজেন্ট যারা আছেন তারা দিতে পারে।</li>
                    </ul>
                    <p>সুপার এজেন্ট নিতে এডমিন এর সাথে যোগাযোগ করুন এবং অনলাইন এজেন্ট - লোকাল এজেন্ট নিতে সুপার এজেন্ট এর সাথে যোগাযোগ করুন</p>
                </div>
            </div> */}

      {/* all low to be agent  */}

      {/* <div className="account-info">
                <div className="quick-master-title">
                    <img src={iconImg} alt="" />
                    <h1>শর্ত সমূহঃ</h1>
                </div>

                <div className="account-info-content">
                    <ul>
                        <li>🎯** এজেন্ট কে ৪২৫০০ টাকায় ৫০০ পয়েন্ট কিনে এজেন্ট শুরু করতে হবে।</li>
                        <li>🎯**  ইউজার ব্যালেন্স বাদ দিয়ে এজেন্ট এর ব্যালেন্স সবসময় ৩০০ পয়েন্ট এর ব্যালেন্স থাকতে হবে।</li>
                        <li>🎯** এজেন্ট এর ব্যালেন্স যদি ১০০০ বা তার বেশি পয়েন্ট হয়ে যায় তাহলে এজেন্ট ৫০০ বা তার বেশি পয়েন্ট বিক্রি করতে পারবে।</li>
                        <li>🎯** এজেন্ট এর কাছে সর্বনিম্ন ১০-২০ জন একটিভ ইউজার থাকতে হবে।</li>
                        <li>🎯 ইউজারদের সব ধরনের হেল্প সাপোর্ট দিতে হবে।</li>
                        <li>🎯 কোন এজেন্ট এর বিরুদ্ধে অভিযোগ প্রমাণিত হলে সাথে সাথে এজেন্টশীপ বাতিল করে দেয়া হবে। বিশেষ সতর্কতাঃ ইউজার উইথড্র চাহিবার মাত্রই ১ মিনিট থেকে ১০ মিনিট এর মধ্যে সকল টাকা ইউজার কে বুঝিয়ে দিতে হবে। </li>
                    </ul>
                    <h6 className='content-desc'>
                        একটা বিষয় অবগত করা দরকার। এজেন্ট এর যদি নিজে খেলার অভ্যাস থাকে তাহলে – এজেন্ট হওয়া থেকে বিরত থাকুন। এজেন্ট যদি কখনো নিজে খেলতে গিয়ে ধরা পরেন – তাহলে এজেন্ট একাউন্ট ঐ অবস্থায় সঙ্গে সঙ্গে ব্লক হয়ে যাবে এবং সমস্ত লেনদেন বন্ধ হয়ে যাবে। যদি এজেন্ট নিজে কোন আইডি তে বাজি ধরিয়ে দিতে চান – তাহলে আপনার সুপার এজেন্ট কে আগেই সেই আইডির বিষয়ে জানাতে হবে।
                    </h6>
                </div>
            </div> */}

      {/* user link  */}

      {/* <div className="account-info">
                <div className="quick-master-title">
                    <img src={iconImg} alt="" />
                    <h1>আমাদের লিংক সমুহ</h1>
                </div>

                <div className="account-info-content">
                    <h4>ইউজার লিংকঃ</h4>
                    <a className='marquee__link' id='link-id' href="http://www.baaji365.live/"> Baaji365.Live </a>
                    <h4>এজেন্ট লিংকঃ</h4>
                    <a className='marquee__link' id='link-id' href="http://www.msa.baaji365.live/"> www.msa.baaji365.live </a>
                    <h4>এজেন্ট লিস্ট লিংকঃ</h4>
                    <a className='marquee__link' id='link-id' href="/superagent.live"> Baaji365.Live </a>
                </div>

            </div> */}

      {/* Socail Media  */}

      {/* <div className="account-info">
                <div className="quick-master-title">
                    <img src={iconImg} alt="" />
                    <h1>সোশাল মিডিয়াঃ</h1>
                </div>

                <div className="account-info-content">
                    <p>আমাদের সম্পর্কে  জানতে জয়েন হতে পারেন আমাদের ফেসবুক গ্রুপ এ । অথবা আমাদের সম্পর্কে আরো নিত্য নতুন আপডেট পেতে জয়েন হতে পারেন আমাদের ফেসবুক পেইজে </p>

                    <h4>ফেসবুক গ্রুপ লিংক</h4>
                    <a className='marquee__link' id='link-id' href="https://bit.ly/3GMZLT0"> https://bit.ly/3GMZLT0 </a>
                    <h4>ফেসবুক পেইজ লিংক</h4>
                    <a className='marquee__link' id='link-id' href="https://bit.ly/3NTz2qg"> https://bit.ly/3NTz2qg </a>
                </div>

            </div> */}
    </div>
  );
};

export default Header;
