import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/luckywin_logo.png";
import configImg from "../../images/configuration.png";

const MasterExtraPage = () => {
  return (
    <div className="main-section" id="home-page">
      <div className="content-section">
        <div className="">
          <div className="logo-section">
            <img src={logo} alt="" />
          </div>
        </div>
        {/* navigation bar  */}
        <div className="navigation-bar">
          <ul>
            <li>
              <Link className="nav-menu" to="/home">
                <i className="fa-solid fa-house"></i>
              </Link>
            </li>
            <li>
              <Link className="nav-menu" to="/customarservice">
                <i className="fa-duotone fa-question question-icon"></i> SERVICE
              </Link>
            </li>

            <li>
              <Link className="nav-menu" to="/admin">
                <img className="configImg" src={configImg} alt="" /> ADMIN
              </Link>{" "}
              {/*<i className="fa-solid fa-user"></i>// */}
            </li>
            {/* <li>
              <Link className="nav-menu" to="/SubAdmin">
                <img className="configImg" src={configImg} alt="" />
                SUB ADMIN
              </Link>
            </li> */}
            <li>
              <Link className="nav-menu" to="/superagent">
                <img className="configImg" src={configImg} alt="" /> SUPER
              </Link>
            </li>
            <li>
              <Link className="nav-menu" to="/onlineagent">
                <img className="configImg" src={configImg} alt="" />
                MASTER
              </Link>
            </li>
            <li>
              <Link className="nav-menu" to="/OLDNEW">
                <img className="configImg" src={configImg} alt="" />
                OLD/NEW
              </Link>
            </li>
          </ul>
        </div>

        <section className="section_bg">
          <div>
            <h1> এজেন্ট হবার নিয়ম </h1>
            <div>
              <p>**********</p>
              <p>পয়েন্ট কেনা বেচা</p>
              <p>**********</p>
              <p>** ** এজেন্ট Luckywin থেকে পয়েন্ট কিনবে ৭০ টাকা করে </p>
              <p>** ** এজেন্ট ইউজারের কাছে বিক্রি করবে ১০০ টাকা করে</p>
              <p>** এইখানে এজেন্টের লাভ থাকবে ৩০% </p>
              <p>**********</p>
              <p>আবারঃ </p>
              <p>**********</p>
              <p>** এজেন্ট ইউজারের কাছ থেকে পয়েন্ট কিনবে ১০০ টাকা করে</p>
              <p>** এজেন্ট বেটুকাছে বিক্রি করবে ৭০ টাকা করে </p>
              <p>** এখানে এজেন্টের লস হবে ৩০% </p>
              <p>**********</p>
              {/* <img className="agent_img" src={agentImg} alt="" /> */}
              <p>
                ** তাহলে প্রশ্ন আসতে পারে এজেন্ট হবার লাভ কোথায়। এজেন্টের মূল
                লাভ হচ্ছে ৫% কমিশন এক্সচেঞ্জে।
              </p>
              <p>
                ** যেমন একজন ইউজার দিনে ১০ টা বেট করল পাঁচটা জিতল এবং পাঁচটা
                হারলো। যে পাঁচটা জিতেছে ইউজার প্রতিবার এজেন্টকে ৫% কমিশন দিয়েছে
                ইউজার।{" "}
              </p>
              <p>
                ** একজন এজেন্টের যদি ২০ থেকে ৫০ জন একটিভ প্লেয়ার থাকে তার মাসে
                ২০ থেকে ৫০ হাজার টাকা কমিশন থাকে। মোটকথা এজেন্ট না হলে এজেন্ট
                হবার লাভ কি আপনি বুঝতে পারবেন না।{" "}
              </p>

              <p>**********</p>
              <p>শর্তসমূহঃ </p>
              <p>**********</p>
              <p>
                ** এজেন্টকে ৩০ হাজার টাকার পয়েন্ট কিনে এজেন্ট শুরু করতে হবে।
              </p>
              <p>
                ** ইউজার ব্যালেন্স বাদ দিয়ে এজেন্টের কাছে সবসময় ৫০০ পয়েন্ট এর
                ব্যালেন্স থাকতে হবে
              </p>
              <p>
                ** এজেন্ট ব্যালেন্স যদি ১০০০ পয়েন্ট হয়ে যায় তাহলে এজেন্ট ৫০০
                পয়েন্ট বেটু কাছে বিক্রি করতে পারবে
              </p>
              <p>** এজেন্টের কাছে সর্বনিম্ন ১০ জন একটি প্লেয়ার থাকতে হবে </p>
              <p>
                ** এজেন্ট ইউজারের সাথে প্রতি পয়েন্ট কেনাবেচা করবে ১০০ টাকা করে
              </p>
              <p>********** </p>
              <p>কি কি কারণে এজেন্ট বাতিল হয়ে যেতে পারেঃ </p>
              <p>********** </p>
              <p>
                ** একটা বিষয়ে অবগত করা দরকার এজেন্টের যদি নিজে খেলার অভ্যাস
                থাকে তাহলে এজেন্ট হওয়া থেকে বিরত থাকুন{" "}
              </p>
              <p>
                ** এজেন্ট যদি কখনো নিজে খেলতে গিয়ে ধরা পড়েন তাহলে এজেন্ট
                একাউন্ট ওই অবস্থায় সঙ্গে সঙ্গে ব্লক হয়ে যাবে এবং সমস্ত লেনদেন
                বন্ধ হয়ে যাবে যদি এজেন্ট নিজে কোন আইডিতে বাজি ধরিয়ে দিতে চান
                তাহলে ফায়ারকে আগেই সেই আইডির বিষয় জানাতে হবে{" "}
              </p>
              <p>** কোন ইউজারের পয়েন্ট ইউজারকে না জানিয়ে তুলে নিলে </p>
              <p>
                ** ইউজারের অ্যাকাউন্ট খোলার সময় কোম্পানির নিয়ম নীতি না মানলে{" "}
              </p>
              <p>
                ** ইউজার একাউন্ট খোলার সময় ইউজারের ফোন নাম্বারের বদলে অন্য কারো
                ফোন নাম্বার ব্যবহার করলে{" "}
              </p>
              <p>** ডিপোজিট উইথড্র ক্ষেত্রে কোম্পানির নিয়ম না মানলে </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default MasterExtraPage;
